import axios from 'axios';
import authHeader from './authHeader';


/**
 * Instance used for ajax calls in User API.
 */
const instance = axios.create({
    baseURL: process.env.REACT_APP_USER_API
});

instance.interceptors.request.use(config=>{
        config.headers.authorization = authHeader().Authorization;
        return config;
    }, err => Promise.reject(err)
);

export default instance;