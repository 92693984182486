import React from 'react';
import ReactTable from 'react-table';
import axiosData from '../../helpers/axiosData';
import {SORT_ORDER} from '../../components/DataViewColumn/DataViewColumn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import {isDate} from '../../helpers/enums';
import {get_locale} from '../../helpers/utils';
import {format, toDate} from 'date-fns';


const loc = get_locale();

/**
 * Component to display list of associated events as table.
 * Props:
 * - rows: initial row
 * - value: object of AssociatedEventsSetting
 * - parentId: id of parent Event
 * - nodeId: nodeId
 */
class Grid extends React.PureComponent {
    _isMount = false;

    state={
        //rows to display
        rows: this.props.rows ? this.props.rows : [],
        //object of AssociatedEventSettings
        view: this.props.value ? this.props.value : {},
        //table loading spinner visibility
        isLoading: true
    }

    /**
     * Ajax call to load rows (events) from API.
     */
    loadRows=()=>{
        this.setState({isLoading: true}, ()=>{
            let view = this.state.view;
            let eventType = view.EventsDataView.WebEntries[0].EventTypePageEntity ? view.EventsDataView.WebEntries[0].EventTypePageEntity.EventType : this.props.eventType;
            axiosData.get(`/v1/${eventType.Name}/event/${this.props.parentId}/associated?nodeid=${this.props.nodeId}&eventtypeid=${eventType.ID}${view.UrlParams?'&'+view.UrlParams:''}`)
                .then(response=>{
                    this.setState({isLoading:false, rows:response.data.Events.Values});
                })
                .catch(error=>{
                    console.log(error);
                    this.setState({isLoading:false});
                });
        });
    }

    /**
     * Call to open associated event oin browser.
     */
    openChildren=(dataEntryId, eventId, nodeid)=>{
        this.props.history.push(`/dataentry/form/${dataEntryId}/${eventId}/${nodeid}/${this.props.parentId}`);
    }

    /**
     * Redirect to new DataEntry form to create new associated event.
     */
    addChildren=()=>{
        if(this.state.view.EventsDataView.NewEventTypePage)
            this.props.history.push(`/dataentry/child/${this.state.view.EventsDataView.NewEventTypePage.ID}/${this.props.parentId}`);
    }

    componentWillUnount=()=>{
        this._isMount = false;
    }

    /**
     * Load initial settings, prepare table settings.
     */
    componentDidMount=()=>{
        this._isMount=true;
        const view = {...this.state.view};
        //Sort columns to respect same order as on Create form
        view.EventsDataView.Columns.sort((a,b) => a.Order > b.Order ? 1 : -1);
        //Add info to columns so it can be directly consumed by table component
        view.EventsDataView.Columns = view.EventsDataView.Columns.map(el=>({
            accessor: el.EventField.Name,
            Header: el.DisplayName ? el.DisplayName : el.EventField.Name,
            width: el.Width ? el.Width : undefined,
            show: el.Visible,
            filter: el.Filter ? (el.Filter.trim() === '{{[@Me]}}'? this.props.context.user.name : el.Filter) : undefined,
            Filter: el.Filter ? ({ filter, onChange }) => (<span></span>): undefined,
            ColumnOrder: SORT_ORDER.find(o=>o.value === el.ColumnOrder),
            className: el.WordWrap ? 'wordWrap' : undefined,
            headerClassName: el.WordWrap ? 'wordWrap' : undefined,
            //if display data is datetime - display it in current locale format
            Cell: prop=>{
                if(prop.value && isDate(el.EventField.BuiltInType)){
                    return (<span>{format(toDate(prop.value), 'P p', {locale:loc})}</span>)
                }
                return (<span>{prop.value}</span>) 
            }
        }));
        //set upgraded view to state
        this.setState({view:view}, (()=>{
            let eventType = view.EventsDataView.WebEntries[0].EventTypePageEntity ? view.EventsDataView.WebEntries[0].EventTypePageEntity.EventType : this.props.eventType;
            if(this._isMount){
                axiosData.get(`/v1/${eventType.ID}/event/${this.props.parentId}/associated?nodeid=${this.props.nodeId}&eventtypeid=${eventType.ID}${view.UrlParams?'&'+view.UrlParams:''}`)
                .then(response=>{
                    this.setState({isLoading:false, rows:response.data.Events.Values});
                })
                .catch(error=>{
                    console.log(error);
                    this.setState({isLoading:false});
                });
            }
        }));
        
    }

    render(){
        if(this.state.view && this._isMount){

            return(
                <React.Fragment>
                    <h4>
                        {this.props.context.t(this.state.view.Label)}
                        {
                            this.state.view.EventsDataView.NewEventTypePage ? 
                                <FontAwesomeIcon className="add-icon" icon="plus" onClick={this.addChildren}/>
                            : null
                        }
                        
                        <FontAwesomeIcon className="float-right refresh-icon" icon="sync-alt" onClick={this.loadRows}/>
                    </h4>
                    {/** table component for more info see react-table docs */}
                    <ReactTable
                        columns={this.state.view.EventsDataView.Columns}
                        data={this.state.rows}
                        loading={this.state.isLoading}
                        minRow={0}
                        className={'-striped -highlight'}
                        filterable
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i'))>-1 : true
                        }}
                        minRows={0}
                        style={{height:'calc(250px)'}}
                        getTrProps={(state, rowInfo, column) => {
                            /**render every row as link to open child event */
                            return {
                                onClick: (e, handleOriginal) => {
                                    this.openChildren(this.state.view.EventsDataView.WebEntries[0].EventTypePageEntityId, rowInfo.original._EventID, rowInfo.original._NodeId);
                                },
                                style: {cursor: 'pointer'}
                            };
                        }}
                        />
                </React.Fragment>
            );
            
        }
        return null;
    }
}

export default withRouter(Grid);